<template>
  <CRow>
    <CCol sm="12">
      <CCard>


        <CCardHeader>
          <h5>Lista {{ titleList }}</h5>
        </CCardHeader>

        <CCardBody>
          <CModal
              v-bind:title="titleModal"
              color="danger"
              centered
              fade
              :show.sync="warningModal"
              footer
              @update:show="closeModal"
          >
            {{ bodyModal }}
          </CModal>
          <CButton
              class="m-1 add-manifesto"
              size="lg"
              color="primary"
              @click="addManifesto()"
          >
            Aggiungi Manifesto
          </CButton>
          <br>
          <br>
          <!-- https://coreui.io/vue/docs/components/table.html -->
          <!-- START CDATA-TABLE -->
          <CDataTable
              :items="loadedItems"
              :fields="fields"
              :table-filter="{ external: true, lazy: true,label:'Cerca',placeholder:'Inserisci nome' }"
              :table-filter-value.sync="tableFilterValue"
              :items-per-page="10"
              :active-page="1"
              outlined
              :noItemsView='{ noResults: "Nessun risultato da filtrare disponibile", noItems: "Nessun dato disponibile" }'

              hover
              :loading="loading"
          >

            <template #fotoManifestoCustom="{item}">
              <td>
                <CImg
                    v-bind:src="resolvePhoto(item)"
                    width="240"
                    height="180"
                    className="mb-6"
                />
              </td>
            </template>

            <template #info="{item}">
              <td>
                <h6>Nome:
                  <div class="bold-text">{{ item.nome_manifesto }}</div>
                </h6>
                <h6>Tipologia:
                  <div class="bold-text">{{ item.tipologia }}</div>
                </h6>

                <h6 v-if="item.nome_chiesa !== null">Luogo rito/ricorrenza:
                  <div class="bold-text">{{ item.nome_chiesa }}</div>
                </h6>
                <h6 v-if="item.tipologia !== 'Ringraziamento' && item.scelta_rito_ricorrenza === 'acceso'">Data
                  rito/ricorrenza:

                  <div class="bold-text">{{ resolveDatetime(item.giorno_ora_messa) }}</div>
                </h6>
              </td>
            </template>

            <template #data_pubblicazione="{item}">
              <td>
                <h6>Inserito:
                  {{ resolveDatetime(item.created_at) }}
                </h6>
                <h6>Pubblicato:
                  <div class="bold-text">{{ resolveDatetime(item.data_pubblicazione) }}</div>
                </h6>
                <h6>Aggiornato:
                  {{ resolveDatetime(item.updated_at) }}
                </h6>
              </td>
            </template>

            <template #visualizzazioni="{item}">
              <td>
                <h6>Visualizzazioni totali:
                  <div class="bold-text">{{ item.visualizzazioni_totali }}</div>
                </h6>

                <h6 v-if="item.visualizzazioni > 0">Visualizzazioni sito:
                  <div class="bold-text">{{ item.visualizzazioni }}</div>
                </h6>

                <h6 v-if="item.visualizzazioni_app > 0">Visualizzazioni app:
                  <div class="bold-text">{{ item.visualizzazioni_app }}</div>
                </h6>

                <h6 v-if="item.conteggio_stati_app > 0">Conteggio stati app:
                  <div class="bold-text">{{ item.conteggio_stati_app }}</div>
                </h6>

                <h6 v-if="item.visualizzazioni_facebook > 0">Visualizzazioni facebook:
                  <div class="bold-text">{{ item.visualizzazioni_facebook }}</div>
                </h6>

                <h6 v-if="item.click_post_facebook > 0">Click post facebook:
                  <div class="bold-text">{{ item.click_post_facebook }}</div>
                </h6>

                <h6 v-if="item.conteggio_stati_facebook > 0">Conteggio stati facebook:
                  <div class="bold-text">{{ item.conteggio_stati_facebook }}</div>
                </h6>

              </td>
            </template>

            <template #comuni="{item}">
              <td>
                <li v-for="value in item.comuni">
                  {{ value.comune }}
                </li>
              </td>
            </template>


            <template #bottoneVisualizzazione="{item}">
              <td>
                <CButton
                    class="m-1 button-manifesto-lista"
                    size="md"
                    color="primary"
                    @click="edit(item)"
                >
                  Modifica
                </CButton>
                <CButton
                    class="m-1 button-manifesto-lista"
                    size="md"
                    color="danger"
                    @click="remove(item)"
                >
                  Cancella
                </CButton>
              </td>
            </template>

            <!-- END CDATA TABLE -->
          </CDataTable>

          <CPagination
              v-show="pages > 1"
              :pages="pages"
              :active-page.sync="activePage"
          />

        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>


<script>
const titleList = "Manifesti"
const bodyModal = "Attenzione! Sei sicuro di voler cancellare permanentemente questo manifesto?";
const titleModal = "Eliminazione Manifesto";
const apiList = '/dashboard/api/v0/manifesti/list';
const apiRemove = '/dashboard/api/v0/manifesti/delete/';

const fields = [
  {
    key: 'fotoManifestoCustom',
    label: 'Foto',
    sort: false,
    filter: false
  },

  {
    key: 'info',
    label: 'Info',
    sort: false,
    filter: false
  },
  {
    key: 'data_pubblicazione',
    label: 'Data',
    sort: false,
    filter: false
  },
  {
    key: 'comuni',
    label: 'Comuni in cui pubblicare',
    sort: false,
    filter: false
  },
  {
    key: 'visualizzazioni',
    label: 'Visualizzazioni',
    sort: false,
    filter: false
  },
  {
    key: 'bottoneVisualizzazione',
    label: 'Operazioni',
    sort: false,
    filter: false
  },
]

export default {
  name: 'ListaManifesti',
  data() {
    return {
      sorterValue: {column: null, asc: true},
      columnFilterValue: {},
      tableFilterValue: '',
      titleList: titleList,
      titleModal: titleModal,
      bodyModal: bodyModal,
      activePage: 1,
      loadedItems: [],
      itemsPerPage: 10,
      idDelete: null,
      loading: false,
      warningModal: false,
      pages: 0,
      currentPages: 1,
      fields: fields
    }
  },
  watch: {
    reloadParams() {
      this.onTableChange()
    }
  },
  computed: {
    reloadParams() {
      return [
        this.sorterValue,
        this.columnFilterValue,
        this.tableFilterValue,
        this.activePage
      ]
    }
  },
  methods: {
    onTableChange() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.getData();
      }, 1000)
    },


    getData() {
      if (this.tableFilterValue.length > 0) {
        axios
            .get(apiList + '?search=' + this.tableFilterValue)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                this.loadedItems = response.data.results;
                let firstValue = _.first(response.data.results)
                this.pages = Math.ceil(firstValue.number / this.itemsPerPage)
              }
            }, (error) => {
              console.log(error);
            });
      } else {
        axios
            .get(apiList + '?page=' + this.activePage)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                this.loadedItems = response.data.results;
                let firstValue = _.first(response.data.results)
                this.pages = Math.ceil(firstValue.number / this.itemsPerPage)
                console.log(this.loadedItems)
              }
            }, (error) => {
              console.log(error);
            });
      }
    },
    edit(item) {
      this.$router.push({
        name: 'manifesto',
        params: {idManifesto: item.id, tipologia: item.tipologia}
      });
    },

    closeModal(status, evt, accept) {
      if (accept) {
        axios
            .delete(apiRemove + this.idDelete)
            .then((response) => {
              console.log(response);
              this.getData();
              this.idDelete = null;
            }, (error) => {
              console.log(error);
            });
      }
    },

    remove(item) {
      this.idDelete = item.id;
      this.warningModal = true;
    },

    resolvePhoto(obj) {
      if (obj !== null && obj.foto !== null) {
        return 'https://funer24-cdn.com/' + obj.foto.foto_pagina_manifesto_mobile;
      } else {
        return obj.foto_manifesto;
      }
    },

    resolveDatetime(datetimeObj) {
      return new Date(datetimeObj).toLocaleString("it-IT")
    },

    addManifesto() {
      this.$router.push({
        name: 'manifesto',
      });
    },
  },

  created() {
    this.pages = 5
    this.getData();
  }
}
</script>
<style>


div.bold-text {
  font-weight: 800;
}

button.add-manifesto.btn-primary {
  right: 10px;
  position: absolute;
}


</style>
